const versionNumber = 0;
const versionDate = '2024.09.18 05:22';
export const environment = {
  apiUrl: 'https://api.platin.inwe.pl',
  envName: "beta",
  feVersion: `${versionDate}`,
  beVersion: 'DATE',
  inProgress: false,
  storageHashKey: "ZZlaiowd7p0a8n3mceaPM:ADANYap;mddASda;pw9m3p0w;fesszs",
  isBeta: false
};
