import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class ApiBaseService {
  constructor(
    protected httpClient: HttpClient,
    protected authService: AuthService
  ) {}

  get headers() {
    return {
      Authorization: `Bearer ${this.authService.jwtToken}`,
    };
  }

  public get<T>(url: string, queryParams: any): Observable<T> {
    return this.httpClient.get<T>(environment.apiUrl + url, {
      headers: this.headers,
      params: queryParams,
    });
  }

  public getBlob(url: string, queryParams: any): Observable<Blob> {
    return this.httpClient.get<Blob>(environment.apiUrl + url, {
      headers: this.headers,
      params: queryParams,
      responseType: 'blob' as 'json',
    });
  }

  public getFullBlob(url: string, queryParams: any): Observable<Blob> {
    return this.httpClient.get<Blob>(url, {
      headers: this.headers,
      params: queryParams,
      responseType: 'blob' as 'json',
    });
  }

  public post<T>(url: string, body: any): Observable<T> {
    return this.httpClient.post<T>(environment.apiUrl + url, body, {
      headers: this.headers,
    });
  }

  public put<T>(url: string, body: any): Observable<T> {
    return this.httpClient.put<T>(environment.apiUrl + url, body, {
      headers: this.headers,
    });
  }

  public patch<T>(url: string, body: any): Observable<T> {
    return this.httpClient.patch<T>(environment.apiUrl + url, body, {
      headers: this.headers,
    });
  }

  public delete(url: string): Observable<any> {
    return this.httpClient.delete(environment.apiUrl + url, {
      headers: this.headers,
    });
  }
}
